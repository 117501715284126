<template>
  <div class="auth-body ribbon-container" :style="{display: displayValue}">

    <div class="auth-el">
      <auth-card>
        <div class="password-set-form">
          <v-form v-model="isValid" ref="form" @submit.prevent="setPassword">
            <v-text-field
                v-model="password"
                :rules="[passwordRules.required, passwordRules.policy]"
                @keyup="matchPasswords"
                class="input-field-size"
                label="Password"
                placeholder="Password"
                outlined
                type="password"
            ></v-text-field>
            <v-text-field
                v-model="confirmPassword"
                :rules="[passwordRules.required, passwordRules.passwordMatch]"
                class="input-field-size"
                label="Re-enter Password"
                placeholder="Re-enter Password"
                outlined
                type="password"
            ></v-text-field>
            <div class="recaptcha-v2-container" ref="recaptchaV2Container"></div>
            <v-btn
                class="set-btn"
                color="primary"
                :disabled="!isValid"
                required
                style="font-size: 22px"
                type="submit"
            >Set Password
            </v-btn>
          </v-form>
        </div>
      </auth-card>
    </div>
  </div>
</template>

<script>
import AuthCard from "@/components/auth/components/AuthCard";
import {PASSWORD_POLICY} from "cpp-js-util/CppUtil";
import encryptionLib from "cpp-js-util/encryption";
import {recaptchaMixin} from "@/components/captcha/recaptcha-mixin";

export default {
  components: {
    AuthCard
  },
  mixins: [recaptchaMixin],
  data() {
    return {
      displayValue: 'none',
      isValid: false,
      wallet: '',
      email: '',
      password: '',
      confirmPassword: '',
      token: '',
      passwordRules: {
        required: v => !!v || 'Password is required',
        policy: v => !!v && PASSWORD_POLICY.test(v) ||
            'Password must have 8+ characters and at least one uppercase, lowercase, digit & special character',
        passwordMatch: v => !!v && v === this.password || 'Password must match'
      },
      autofilled: false,
    }
  },
  async created() {
    try {
      this.$feedback.showLoading()
      this.token = this.$route.params.token;
      let {data} = await this.$cppClient.post('set-password/token', {
        token: this.token
      });
      this.$feedback.hideLoading()
      this.displayValue = ''
      this.wallet = data.wallet
      this.email = data.email
      await this.loadAndPrepareRecaptchaDetails();
    } catch (err) {
      console.warn(err)
      this.$feedback.hideLoading()
      this.$logger.loggable(err).log()
      await this.$router.push({name: 'error', params: {error: this.$lodash.get(err, 'response.data')}})
    }
  },
  methods: {
    async attemptAutoLogin() {
      console.debug("Attempting to auto login user");

      let payload = {phoneNumber: this.wallet, password: this.password};
      let {nonce, publicKey} = await encryptionLib.getNonceAndRsaPublicKey(this.$cppClient, this.$feedback);
      payload.password = encryptionLib.encryptWithRsaPublicKeyAndNonce(publicKey, nonce, payload.password);

      await this.loadAndPrepareRecaptchaDetails();
      payload.recaptchaDetails = this.recaptcha;

      try {
        let res = await this.$cppClient.post('/login', payload);
        this.$feedback.hideLoading();
        this.$store.commit('setCredentials', res.data);
        this.$gtag.event("First time logged in", {
          'event_category': "Sign In Page"
        });
        await this.$router.push({name: 'dashboard', params: {firstTimeLogin: true}});
      } catch (ex) {
        let {response} = ex;
        let {data} = response;
        if (data.internalCode === 'RECAPTCHA_V3_LOW_SCORE' || data.internalCode === 'RECAPTCHA_IS_REQUIRED') {
          console.debug("Auto login failed due to captcha failure. Redirecting to login page.");
          await this.$router.push({name: 'sign-in', params: {firstTimeLogin: true}});
        } else {
          await this.$feedback.showFailed(response);
          console.warn(ex);
          console.debug("Auto login failed due to some issue Redirecting to login page.");
          await this.$router.push({name: 'sign-in', params: {firstTimeLogin: true}});
        }
      }
    },

    async setPassword() {
      try {
        let payload = {token: this.token, password: this.password}
        let {nonce, publicKey} = await encryptionLib.getNonceAndRsaPublicKey(this.$cppClient, this.$feedback);
        payload.password = encryptionLib.encryptWithRsaPublicKeyAndNonce(publicKey, nonce, payload.password);

        this.$feedback.showLoading()
        await this.$cppClient.post('set-password', payload);

        await this.attemptAutoLogin();
      } catch (err) {
        console.warn(err)
        await this.$feedback.showFailed(err.response)
      }
    },
    matchPasswords() {
      if (this.confirmPassword) {
        this.$refs.form.validate()
      }
    }
  }
}
</script>

<style scoped>
.password-set-form .input-el {
  margin: 35px 0 !important;
}

.set-btn {
  font-size: 24px !important;
  letter-spacing: normal !important;
  margin-top: 10px;
  margin-bottom: 40px;
  width: 329px;
  height: 39px;
}

.input-field-size {
  margin-bottom: 42px;
  width: 329px !important;
  height: 64px;
  display: inline-block;
}

.input-field-size >>> .v-messages__message {
  hyphens: none !important;
}

@media screen and (max-width: 425px) {
  .input-field-size {
    width: 100%;
    margin-bottom: 52px;;
  }

  .set-btn {
    width: 100% !important;
    display: inline-block;
  }

  .password-set-form {
    display: inline-block;
    width: 261px !important;
  }

  .input-field-size >>> .v-messages__message {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 425px) {
  .recaptcha-v2-container {
    transform: scale(0.8);
    transform-origin: 0 0;
  }
}

.recaptcha-v2-container {
  width: 329px !important;
  margin: auto;
  margin-top: -12px;
  margin-bottom: 12px;
}
</style>
